<template>
  <div class="whiteboard-wrap">
    <!-- 无数据 -->
    <div class="conment" v-if="boardObj && boardObj.type == 'noData'">
      <img src="@/assets/index/empty@3x.png">
      <div v-for="(item, index) in boardObj.textList" :key="index">{{ item }}</div>
    </div>
    <!-- 查询无结果 -->
    <div class="conment" v-if="boardObj && boardObj.type == 'queryNoResult'">
      <img src="@/assets/index/(query) none data@3x.png">
      <div v-for="(item, index) in boardObj.textList" :key="index">{{ item }}</div>
    </div>
    <!-- 无网络 -->
    <div class="conment" v-if="boardObj && boardObj.type == 'noNetWork'">
      <img src="@/assets/index/network disconnection@3x.png">
      <div v-for="(item, index) in boardObj.textList" :key="index">{{ item }}</div>
      <div v-for="(bItem, bIndex) in boardObj.button" :key="'button_'+bIndex" @click="operation(index)">
        <el-button type="primary">{{bItem}}</el-button>
      </div>
    </div>
    <!-- 系统异常 -->
    <div class="conment" v-if="boardObj && boardObj.type == 'systemError'">
      <img src="@/assets/index/systerm error@3x.png">
      <div v-for="(item, index) in boardObj.textList" :key="index">{{ item }}</div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'white-board',
  props: {
    boardObj: {
      typeof: Object,
      default: () => {
        return {
          type: '', // noData数据为空 queryNoResult查询无结果 noNetWork无网络 systemError系统异常
          textList: [], // 存放需要显示的文字（分行）
          button: [] // 存放需要显示的按钮（个数）
        }
      }
    }
  },
  operation (i) {
    this.$emit(`submitOperation_${i}`)
  }
}
</script>

<style lang="scss" scoped>
.whiteboard-wrap{
  background-color: #fff;
  margin-top: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  .conment{
    padding: 24px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    img{
      width: 120px;
      height: 120px;
    }
    div{
      margin-top: 24px;
      color: #999999;
      font-size: 14px;
    }
  }
}
</style>
