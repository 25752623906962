/* * @Author: Mini Wei  * @Date: 2022-10-25 15:32:59  * @Last Modified by:   Dana Zhu  * @Last Modified time: 2022-10-25 15:32:59  */
export function unique (arr) {
  // 数组去重[1,1,2,2,,3,3,4,5]
  for (var i = 0; i < arr.length; i++) {
    for (var j = i + 1; j < arr.length; j++) {
      if (arr[i] === arr[j]) {
        // 第一个等同于第二个，splice方法删除第二个
        arr.splice(j, 1)
        j--
      }
    }
  }
  return arr
}

export function uniqueByValue (data, value) {
  // data: filter array, value: filter label
  /**
   * sample:
   * array = [
   * {
   *  country: 'SA',
   *  location: 'AHB'
   * },
   * {
   *  country: 'GR',
   *  location: 'ATH'
   * },
   * {
   *  country: 'SA',
   *  location: 'AHB'
   * }
   * ]
   */
  const resp = new Map()
  return data.filter(
    (item) => !resp.has(item[value]) && resp.set(item[value], 1)
  )
}

export function clickCopyValue (val) { // Copy value
  /**
   * @author Dana Zhu
   */
  let inputNode = document.createElement('input') // 创建input
  inputNode.value = val // 赋值给 input 值
  document.body.appendChild(inputNode) // 插入进去
  inputNode.select() // 选择对象
  document.execCommand('Copy') // 原生调用执行浏览器复制命令
  inputNode.className = 'oInput'
  inputNode.style.display = 'none' // 隐藏
  this.$notify({
    title: 'Copied.',
    type: 'success',
    showClose: false,
    duration: 3000
  })
}
