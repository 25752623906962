<template>
  <div class="drawer">
    <el-drawer
      :title="config.title"
      :visible.sync="childDrawer"
      :before-close="onClose"
      :show-close="false"
      :size="332"
      direction="rtl"
      >
      <div class="drawer-container">
        <slot></slot>
      </div>
      <div class="submit-button">
        <el-button size="small" @click="onReset" >{{ config.resetText }}</el-button>
        <el-button type="primary" size="small" @click="onConfirm">{{ config.confirmText }}</el-button>
      </div>
    </el-drawer>
  </div>
</template>

<script>
export default {
  props: {
    drawer: {
      type: Boolean,
      default: false
    },
    config: {
      type: Object,
      default: () => {
        return {
          title: 'title',
          resetText: 'Reset',
          confirmText: 'Query'
        }
      }
    }
  },
  watch: {
    drawer (newVal) {
      console.log('newVal', newVal)
      this.childDrawer = newVal
    }
  },
  data () {
    return {
      childDrawer: this.drawer
    }
  },
  methods: {
    onConfirm () {
      this.$emit('onConfirm')
    },
    onReset () {
      this.$emit('onReset')
    },
    onClose (done) {
      this.$emit('onClose')
      done()
    }
  }
}
</script>

<style lang="scss" scoped>
.drawer{
  ::v-deep{
    .el-drawer__header{
      padding: 16px !important;
    }
    .el-drawer__body{
      padding: 0 16px !important;
    }
    .el-date-editor--daterange.el-input, .el-date-editor--daterange.el-input__inner, .el-date-editor--timerange.el-input, .el-date-editor--timerange.el-input__inner{
      width: 300px;
    }
    .el-select {
      width: 300px;
    }
    .el-form-item {
      margin-bottom: 16px;
    }
    .el-form-item__label{
      line-height: 22px !important;
      margin-bottom: 4px;
    }
    .el-range__icon {
      display: none;
    }
    .el-range-input {
      width: 90px;
      text-align: justify;
    }
    .el-input__inner {
      color: #333;
    }
    .el-range-editor.el-input__inner {
      margin-right: -22px;
    }
  }
}
</style>
