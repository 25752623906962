<template>
  <div class="breadcrumb_wrap">
    <el-breadcrumb>
      <!-- [index != breadcrumbObj.title.length-1 ?'breadcrumb-title-high':'breadcrumb-title-low'] -->
      <el-breadcrumb-item v-for="(item, index) in breadcrumbObj.title" :key="'breadcrumb_'+index" :class="{'breadcrumb-title-low' : index === breadcrumbObj.title.length-1, 'breadcrumb-title-high' : index != breadcrumbObj.title.length-1, 'breadcrumb-title-not-drop' : index === 0}" :to="{ path: item.path }">{{item.name}}</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="nav-title">
      <div class="nav-left">
        <p class="nav-back" v-show="breadcrumbObj.showBackbtn" @click="goBack()">
          <i class="el-icon-arrow-left nav-back-left-arrow"></i>
          Back
        </p>
        <p>{{breadcrumbObj.title[breadcrumbObj.title.length-1].name}}</p>
        <p class="nav-subname">{{breadcrumbObj.subName}}</p>
        <div v-show="breadcrumbObj.subName" @click="copyValue(breadcrumbObj.subName)">
          <svg-icon icon-class="copy" class="nav-icons" />
        </div>
        <i v-show="breadcrumbObj.showResetbtn" @click="reLoadCurrentPage()" class="el-icon-refresh-left common-icon"></i>
        <slot></slot>
      </div>
      <div>
        <div class="nav-button">
          <el-button v-for="(el, index) in breadcrumbObj.button" :key="'breadcrumb_button_'+index" :type="el.type" :plain="el.plain" size="small" class="public-button-color public-fonts" @click="confirm(el.title, index)">
            {{el.title}}
            <i :class="el.icon"></i>
          </el-button>
          <div class="limosa-dropdown" v-for="(el, index) in breadcrumbObj.button" :key="'dropdown_'+index" v-show="el.dropdown && el.dropdown.show">
            <div class="limosa-dropdown-item" v-for="(els, sindex) in el.dropdown && el.dropdown.dropdownList" :key="'dropdown_item_'+sindex" @click="openOperate(sindex)">{{els}}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/*
 * @Author: MiniWei
 * @Date: 2022-09-07 16:08:23
 * @Last Modified by: Dana Zhu
 * @Last Modified time: 2022-11-08 16:01:06
 */
/**
 * breadcrumbObj: { // 标题头内容
        title: [
          {
            name: 'Cargo Receiving',
            path: '/cargo-receiving/security-screening/list'
          }
        ],
        showResetbtn: false, // 显示重新加载页面按钮
        showBackbtn: true, // 显示返回按钮
        button: [
          {
            type: 'primary',
            title: 'Create',
            plain: null,
            icon: 'el-icon-arrow-down',
            dropdown: { // 下拉选择框
              show: false,
              dropdownList: [
                'Upload SLI and Create',
                'Manual Create'
              ]
            }
          },
          {
            type: null,
            title: 'Query',
            plain: 'plain'
          }
        ],
        subName: ''
      },
 */
export default {
  name: 'breadcrumb',
  inject: ['reload'],
  props: {
    breadcrumbObj: {
      typeof: Object,
      default: () => {
        return {
          title: [],
          showResetbtn: false,
          showBackbtn: false,
          button: [],
          subName: ''
        }
      }
    }
  },
  data () {
    return {

    }
  },
  methods: {
    confirm (title, index) {
      this.$emit(`confirm_${index}`, title)
    },
    goBack () {
      this.$emit('back')
    },
    openOperate (index) { // 子菜单click事件
      this.$emit(`openOperate_${index}`)
    },
    reLoadCurrentPage () { // Reflesh
      this.reload()
    },
    copyValue (val) { // Copy
      let inputNode = document.createElement('input') // 创建input
      inputNode.value = val // 赋值给 input 值
      document.body.appendChild(inputNode) // 插入进去
      inputNode.select() // 选择对象
      document.execCommand('Copy') // 原生调用执行浏览器复制命令
      inputNode.className = 'oInput'
      inputNode.style.display = 'none' // 隐藏
      this.$notify({
        title: 'Copied.',
        type: 'success',
        showClose: false,
        duration: 3000
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.breadcrumb_wrap{
  background-color: #ffffff;
  padding: 24px 24px 16px 24px;
  ::v-deep{
    .el-breadcrumb{
      font-size: 14px;
      line-height: 22px;
    }
    .el-breadcrumb__inner{
      color: #999999;
    }
    .el-breadcrumb__item:last-child .el-breadcrumb__inner{
      color: #666666;
    }
    .el-breadcrumb__inner a, .el-breadcrumb__inner.is-link{
      color: #999999;
      font-size: 14px;
      font-weight: 400;
    }
    .breadcrumb-title-not-drop .el-breadcrumb__inner.is-link {
      cursor: no-drop;
    }
  }
  .breadcrumb-title-high{
    font-size: 14px;
    color: #999999;
  }
  .breadcrumb-title-low{
    font-size: 14px;
    color: #666666;
  }
  .nav-title{
    display: flex;
    justify-content: space-between;
    /* align-items: baseline; */
    height: 32px;
    margin-top: 16px;
    .nav-left{
      display: flex;
      justify-content: center;
      align-items: center;
      .nav-back{
        color: #1890ff;
        font-size: 14px;
        cursor: pointer;
        font-weight: 400;
        margin-right: 16px;
        .nav-back-left-arrow {
          font-weight:900;
        }
      }
      .nav-subname {
        margin-left: 16px;
        font-weight: 700;
        font-size: 14px;
        line-height: 22px;
        color: #1890FF;

      }
      p{
        margin: 0;
        font-size: 24px;
        line-height: 32px;
        font-weight: 300;
      }
    }
  }

  .nav-button {
    position: relative;
    .limosa-dropdown {
      display: flex;
      flex-direction: column;
      background: #FFFFFF;
      box-shadow: 0 0 20px rgba(0, 0, 0, 0.12);
      border-radius: 4px;
      padding: 4px 0;
      margin-top: 5px;
      position: relative;
      left: -105px;
      cursor: pointer;
      .limosa-dropdown-item {
        padding: 9px 20px;
        font-size: 14px;
        line-height: 22px;
        color: #333333
      }
      .limosa-dropdown-item:hover {
        color: #1890FF;
      }
    }
  }
  .common-icon {
    color: #1890FF;
    font-size: 24px;
    cursor: pointer;
  }
  .nav-icons {
    cursor: pointer;
    width: 16px;
    height: 16px;
    margin-left: 8px;
  }
}
</style>
