<template>
  <div class="security-screening-wrap">
    <Breadcrumb :breadcrumbObj="breadcrumbObj" @confirm_0="lookUpAndRecord()" @confirm_1="openQeury()"></Breadcrumb>
    <!-- 总数据展示 -->
    <div class="total-data-wrap">
      <div v-for="(item,index) in totalDataList" :key="'totalData_'+index" class="total_data_Item">
        <div class="item-type">{{item.type}}</div>
        <div class="item-value-label-box">
          <span class="item-value" :class="{'colorRejuct': item.type == 'Reject Qty', 'colorSuccess': item.type == 'Pass Qty'}">{{item.value}}</span>
          <span class="item-label">{{item.label}}</span>
        </div>
      </div>
    </div>
    <!-- 数据列表 -->
    <div class="data-list-box">
      <div class="list-box">
        <el-table :key="tableShow" :data="dataList" class="inventory-table" ref="multipleTable" header-row-class-name="inventory-header-height" @selection-change="selectionItem">
          <el-table-column type="selection" width="80" align="center" fixed="left" :reserve-selection="true"></el-table-column>
          <el-table-column
          v-for="(cols,index) in dataCols"
            :prop="propsTtranslateCols(cols.label)"
            :label="cols.label"
            :fixed="cols.location"
            sortable
            :key="cols.label + index"
            :width="colsWidth(cols.label)"
            class-name="hhhhhhh">
          </el-table-column>
          <el-table-column
            align="center"
            fixed="right"
            label-class-name="header-setting-icon"
            width="80">
            <template slot="header" slot-scope="scope">
              <el-dropdown trigger="click">
                <span class="icon-font"><svg-icon icon-class="header setting" style="width:19.88px;height:21px;vertical-align: -6px;"/></span>
                <el-dropdown-menu slot="dropdown" class="header-setting-box">
                  <span class="header-setting-title">Header Setting {{ scope.row }}</span>
                  <div class="scoll-container">
                    <div class="save-option">
                      <div>Keep My Options</div>
                      <el-switch
                        v-model="keepMyOptions"
                        active-color="#1890ff"
                        inactive-color="#CCCCCC"
                        @change="optionsChange">
                      </el-switch>
                    </div>
                    <div class="option-box">
                      <el-checkbox :indeterminate="isIndeterminate" v-model="checkAll" @change="checkAllChange">Select All</el-checkbox>
                      <div class="checkboxTitle">Fixed to the left</div>
                      <el-checkbox-group v-model="checkedColsLeft" @change="checkedColsLeftChange">
                        <draggable v-model="leftFixed" group="site" filter=".forbid" animation="300" @start="onStart" @end="onEnd">
                          <transition-group :style="leftFixed.length == 0 ? 'min-height: 38px !important; width: 283px;display:block;': ''">
                            <div v-for="item in leftFixed" :key="item" class="checkboxItem">
                              <svg-icon icon-class="drag" style="width:20px;height:38px;vertical-align:-6px;margin-right:8px;"/>
                              <el-checkbox :label="item" :disabled="disabledCols(item)">{{ item }}</el-checkbox>
                            </div>
                          </transition-group>
                        </draggable>
                      </el-checkbox-group>
                      <div class="checkboxTitle">Not fixed</div>
                      <el-checkbox-group v-model="checkedColsNot" @change="checkedColsNotChange">
                        <draggable v-model="notFixed" group="site" filter=".forbid" animation="300" @start="onStart" @end="onEnd">
                          <transition-group :style="notFixed.length == 0 ? 'min-height: 38px !important; width: 283px;display:block;': ''">
                            <div v-for="item in notFixed" :key="item" class="checkboxItem">
                              <svg-icon icon-class="drag" style="width:20px;height:38px;vertical-align:-6px;margin-right:8px;"/>
                              <el-checkbox :label="item" :disabled="disabledCols(item)">{{ item }}</el-checkbox>
                            </div>
                          </transition-group>
                        </draggable>
                      </el-checkbox-group>
                      <div class="checkboxTitle">Fixed to the right</div>
                      <el-checkbox-group v-model="checkedColsRight" @change="checkedColsRightChange">
                        <draggable v-model="rightFixed" group="site" filter=".forbid" animation="300" @start="onStart" @end="onEnd">
                          <transition-group :style="rightFixed.length == 0 ? 'min-height: 38px !important; width: 283px;display:block;': ''">
                            <div v-for="item in rightFixed" :key="item" class="checkboxItem">
                              <svg-icon icon-class="drag" style="width:20px;height:38px;vertical-align:-6px;margin-right:8px;"/>
                              <el-checkbox :label="item" :disabled="disabledCols(item)">{{ item }}</el-checkbox>
                            </div>
                          </transition-group>
                        </draggable>
                      </el-checkbox-group>
                    </div>
                  </div>
                  <div class="select-btn">
                    <el-button size="small" class="public-fonts" @click="reset()" >Reset</el-button>
                    <el-dropdown-item><el-button type="primary" size="small" class="public-fonts" @click="confirm()">Confirm</el-button></el-dropdown-item>
                  </div>
                </el-dropdown-menu>
              </el-dropdown>
            </template>
            <template slot-scope="scope">
              <el-popover
                placement="bottom"
                width="200"
                @show="showPopover(scope.row,scope.$index)"
                @hide="hidePopover(scope.row,scope.$index)"
                trigger="click"
                :disabled="!scope.row.disabledTooltip">
                <div>
                  <div class="action-print" @click="actionFrame('Record Result',scope.row,scope.$index)">Record Result</div>
                  <div class="action-print" @click="actionFrame('Update Result',scope.row,scope.$index)">Update Result</div>
                  <div class="action-print" @click="actionFrame('Print Security Receipt',scope.row,scope.$index)">Print Security Receipt</div>
                </div>
                <div slot="reference">
                  <span v-show="scope.row.moreActions==false && scope.row.disabledTooltip==true" class="bg-image-default"></span>
                  <span v-show="scope.row.moreActions==true && scope.row.disabledTooltip==true" class="bg-image-focuse"></span>
                  <el-tooltip :disabled="scope.row.disabledTooltip" class="item" effect="dark" content="No Actions." placement="top-end" :visible-arrow="false">
                    <span v-show="!scope.row.disabledTooltip" class="bg-image-disabled"></span>
                  </el-tooltip>
                </div>
              </el-popover>
            </template>
          </el-table-column>
        </el-table>
        <div class="list-pagination pagination" v-if="dataList.length > 0">
          <div class="left-function">
            <el-checkbox v-model="datacChecked" :indeterminate="isIndeterminateData" @change="selectAllbottomChange">Select All</el-checkbox>
            <el-button plain size="mini">Record Result</el-button>
            <el-button plain size="mini">Export Log</el-button>
          </div>
          <div class="right-function">
            <el-pagination
              @size-change="sizeChange"
              @current-change="currentChange"
              :current-page="currentPage"
              :page-sizes="[10, 20, 30, 40]"
              :page-size="pageSize"
              layout="total, prev, pager, next, sizes, jumper"
              :total="total">
            </el-pagination>
            <div class="divider"></div>
            <div class="back-top">
              <svg-icon icon-class="back_to_top_on_white" style="width:24px;height:24px;vertical-align: -6px;"/>
            </div>
          </div>
        </div>
      </div>
      <Whiteboard v-if="dataList.length == 0" :boardObj="boardObj"></Whiteboard>
    </div>
    <!-- Query -->
    <drawerRight
      :config="QueryConfig"
      :drawer.sync="drawer"
      @onConfirm="drawerOnConfirm"
      @onReset="draweronReset"
      @onClose="draweronClose"
    >
    <el-form
        label-position="top"
        label-width="80px"
      >
        <el-form-item label="Booking No.">
          <el-input v-model="queryParams.bookingNo" placeholder="Input suffix to search ( ≥3 chars)" clearable>
            <i slot="prefix" class="el-input__icon el-icon-search"></i>
          </el-input>
        </el-form-item>
        <el-form-item label="AWB No.">
          <el-input v-model="queryParams.awbNo" placeholder="Input suffix to search ( ≥3 chars)" clearable>
            <i slot="prefix" class="el-input__icon el-icon-search"></i>
          </el-input>
        </el-form-item>
        <el-form-item label="Booking Date Range">
          <el-date-picker
            v-model="queryParams.bookingDateRange"
            type="daterange"
            format="yyyy - MM - dd"
            value-format="yyyy-MM-dd"
            start-placeholder="Start Date"
            end-placeholder="End Date"
            range-separator="-"
          >
          </el-date-picker>
          <svg-icon icon-class="calendar" style="position: relative;left: -10px;top: 3px;" />
        </el-form-item>
        <el-form-item label="Flight Date Range">
          <el-date-picker
            v-model="queryParams.flightDateRange"
            type="daterange"
            format="yyyy - MM - dd"
            value-format="yyyy-MM-dd"
            range-separator="-"
            start-placeholder="Start Date"
            end-placeholder="End Date"
          >
          </el-date-picker>
          <svg-icon icon-class="calendar" style="position: relative;left: -10px;top: 3px;" />
        </el-form-item>
        <el-form-item label="Destination">
          <el-input v-model="queryParams.destination" placeholder="Search" clearable>
            <i slot="prefix" class="el-input__icon el-icon-search"></i>
          </el-input>
        </el-form-item>
        <el-form-item label="Shipper Code/Name">
          <el-input v-model="queryParams.shipper" placeholder="Input suffix to search ( ≥3 chars)" clearable>
            <i slot="prefix" class="el-input__icon el-icon-search"></i>
          </el-input>
        </el-form-item>
        <el-form-item label="Screening Date Range">
          <el-date-picker
            v-model="queryParams.screeningDateRange"
            type="daterange"
            format="yyyy - MM - dd"
            value-format="yyyy-MM-dd"
            range-separator="-"
            start-placeholder="Start Date"
            end-placeholder="End Date"
          >
          </el-date-picker>
          <svg-icon icon-class="calendar" style="position: relative;left: -10px;top: 3px;" />
        </el-form-item>
        <el-form-item label="Screening Result">
          <el-select clearable v-model="queryParams.screeningResult" placeholder="choose">
            <el-option
              v-for="item in screeningResultOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="Printing Result">
          <el-select clearable v-model="queryParams.printingResult" placeholder="choose">
            <el-option
              v-for="item in screeningResultOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
    </drawerRight>
  </div>
</template>

<script>
import Breadcrumb from '@/components/breadcrumb/breadcrumb.vue'
import Whiteboard from '@/components/common-whiteboard/whiteboard.vue'
import drawerRight from '../../components/drawer-right.vue'
import draggable from 'vuedraggable'
import { unique } from '@/utils/common'

export default {
  name: 'security-screening-index',
  components: {
    Breadcrumb,
    Whiteboard,
    drawerRight,
    draggable
  },
  data () {
    return {
      breadcrumbObj: { // 标题头内容
        title: [
          {
            name: 'Cargo Receiving',
            path: null
          },
          {
            name: 'Security Screening',
            path: '/cargo-receiving/security-screening/list'
          }
        ],
        button: [
          {
            type: 'primary',
            title: 'Look-up and Record',
            plain: null
          },
          {
            type: null,
            title: 'Query',
            plain: 'plain'
          }
        ]
      },
      boardObj: { // 公共白板样式
        type: 'noData',
        textList: ['Click Query to check X-Ray screening tasks.'], // , 'Query no result'
        button: []
      },
      totalDataList: [ // 安检总数据指数
        {
          type: 'Pending Qty',
          value: 123,
          label: 'Booking'
        },
        {
          type: 'Pass Qty',
          value: 123,
          label: 'Booking/Today'
        },
        {
          type: 'Reject Qty',
          value: 3,
          label: 'Booking/Today'
        },
        {
          type: 'Pass Rate',
          value: '97.79%',
          label: 'Today'
        }
      ],
      currentPage: 1, // 当前页数
      pageSize: 10, // 当前页条数
      total: 0, // 安检总数据条数
      datacChecked: false, // 安检底部全选框
      dataList: [ // 安检表格数据
        {
          bookingNo: 'CIL-220519-0001',
          awbNo: '160-40780342',
          shipper: 'air sea',
          screeningDate: '7/22/2022',
          screeningTime: '10:53',
          screeningMethod: 'X-Ray, Hand Search',
          screeningResult: 'Reject',
          printingResult: 'Printed',
          cargoReceivingJobID: 'cargoReceivingJobID',
          disabledTooltip: true,
          moreActions: false
        },
        {
          bookingNo: 'CIL-220519-0001',
          awbNo: '160-40780342',
          shipper: 'air sea',
          screeningDate: '7/22/2022',
          screeningTime: '10:53',
          screeningMethod: 'X-Ray, Hand Search',
          screeningResult: 'Reject',
          printingResult: 'Printed',
          cargoReceivingJobID: 'cargoReceivingJobID',
          disabledTooltip: true,
          moreActions: false
        }
      ],
      dataCols: [ // 安检表格中 显示列名 数据
        {
          label: 'Booking No.',
          location: false
        },
        {
          label: 'Cargo Receiving Job ID',
          location: false
        },
        {
          label: 'Screening Result',
          location: false
        },
        {
          label: 'Printing Result',
          location: false
        }
      ],
      dataPropsTtranslateCols: [ // 根据label获取props和translate的值
        {
          label: 'Booking No.',
          props: 'bookingNo',
          translate: 'BookingNo'
        },
        {
          label: 'Cargo Receiving Job ID',
          props: 'cargoReceivingJobID',
          translate: 'CargoReceivingJobID'
        },
        {
          label: 'Booking Date',
          props: 'bookingDate',
          translate: 'BookingDate'
        },
        {
          label: 'Est. Arrival at Warehouse Date',
          props: 'estArrivalAtWarehouseDate',
          translate: 'EstArrivalatWarehouseDate'
        },
        {
          label: 'AWB No.',
          props: 'awbNo',
          translate: 'AWBNo'
        },
        {
          label: 'Flight Date',
          props: 'flightDate',
          translate: 'FlightDate'
        },
        {
          label: 'Departure',
          props: 'departure',
          translate: 'Departure'
        },
        {
          label: 'Departure',
          props: 'departure',
          translate: 'Departure'
        },
        {
          label: 'Destination',
          props: 'destination',
          translate: 'Destination'
        },
        {
          label: 'Shipper',
          props: 'shipper',
          translate: 'Shipper'
        },
        {
          label: 'Consignee',
          props: 'consignee',
          translate: 'Consignee'
        },
        {
          label: 'Total Weight(Kg)',
          props: 'totalWeight',
          translate: 'TotalWeight(Kg)'
        },
        {
          label: 'Total Volume(CBM)',
          props: 'totalVolume',
          translate: 'TotalWeight(Kg)'
        },
        {
          label: 'Total Piece(PCS)',
          props: 'totalPiece',
          translate: 'TotalPiece(PCS)'
        },
        {
          label: 'Screener ID',
          props: 'screenerID',
          translate: 'ScreenerID'
        },
        {
          label: 'Screening Date',
          props: 'screeningDate',
          translate: 'ScreeningDate'
        },
        {
          label: 'Screening Time',
          props: 'screeningTime',
          translate: 'ScreeningTime'
        },
        {
          label: 'Screening Method',
          props: 'screeningMethod',
          translate: 'ScreeningMethod'
        },
        {
          label: 'Screening Remark',
          props: 'screeningRemark',
          translate: 'ScreeningRemark'
        },
        {
          label: 'Screening Result',
          props: 'screeningResult',
          translate: 'ScreeningResult'
        },
        {
          label: 'Printing Result',
          props: 'printingResult',
          translate: 'Printing Result'
        }
      ],
      drag: false, // 默认拖拽
      keepMyOptions: false, // 默认保存所选的选项（安检表格）
      isIndeterminate: true, // header Setting的全选框联动状态
      isIndeterminateData: false, // 安检表格的全选框联动状态
      checkAll: false, // header Setting的全选框值
      selectCheckAll: [],
      checkAllOption: [],
      checkedColsLeft: [], // header Setting的左侧选值
      checkedColsNot: [], // header Setting的不固定选值
      checkedColsRight: [], // header Setting的右侧选值
      leftFixed: [], // header Setting的左侧可选内容
      notFixed: ['Booking No.', 'Cargo Receiving Job ID', 'Booking Date', 'Est. Arrival at Warehouse Date', 'AWB No.', 'Flight Date', 'Departure', 'Destination', 'Shipper', 'Consignee', 'Total Weight(Kg)', 'Total Volume(CBM)', 'Total Piece(PCS)', 'Screener ID', 'Screening Date', 'Screening Time', 'Screening Method', 'Screening Remark', 'Screening Result', 'Printing Result'], // header Setting的不固定可选内容
      rightFixed: [], // header Setting的右侧可选内容
      disabledColsArray: ['Booking No.', 'Cargo Receiving Job ID', 'Screening Result', 'Printing Result'], // header Setting的默认选中值，且不能修改
      tableShow: true, // 更新table的key
      dataCheckAll: [], // 安检表格的选中值
      QueryConfig: { // QUERY的名称和按钮
        title: 'Query Criteria',
        resetText: 'Reset',
        confirmText: 'Query'
      },
      drawer: false, // query抽屉的初始值
      queryParams: { // query参数
        bookingNo: '',
        awbNo: '',
        bookingDateRange: '',
        flightDateRange: '',
        destination: '',
        shipper: '',
        screeningDateRange: '',
        screeningResult: '',
        printingResult: ''
      },
      screeningResultOptions: [
        {
          label: 'Error',
          value: 'Error'
        }
      ]
    }
  },
  mounted () {
    this.defaultSort()
  },
  computed: {
    disabledCols () {
      return (val) => {
        for (let item of this.disabledColsArray) {
          if (val === item) return true
        }
      }
    },
    propsTtranslateCols () {
      return (val) => {
        for (let item of this.dataPropsTtranslateCols) {
          if (val === item.label) return item.props
        }
      }
    },
    colsWidth () {
      return (val) => {
        if (val === 'Booking No.') return '164'
        if (val === 'Cargo Receiving Job ID') return '250'
        if (val === 'Booking Date') return '164'
        if (val === 'Est. Arrival at Warehouse Date') return '344'
        if (val === 'AWB No.') return '164'
        if (val === 'Flight Date') return '188'
        if (val === 'Departure') return '131'
        if (val === 'Destination') return '158'
        if (val === 'Shipper') return '186'
        if (val === 'Consignee') return '186'
        if (val === 'Total Weight(Kg)') return '188'
        if (val === 'Total Volume(CBM)') return '188'
        if (val === 'Total Piece(PCS)') return '188'
        if (val === 'Screener ID') return '144'
        if (val === 'Screening Date') return '188'
        if (val === 'Screening Time') return '186'
        if (val === 'Screening Method') return '188'
        if (val === 'Screening Remark') return '188'
        if (val === 'Screening Result') return '186'
        if (val === 'Printing Result') return '188'
      }
    }
  },
  watch: {
    leftFixed: {
      handler (newName, oldName) {
        this.getSelectCheckAll()
      },
      deep: true
    },
    notFixed: {
      handler (newName, oldName) {
        this.getSelectCheckAll()
      },
      deep: true
    },
    rightFixed: {
      handler (newName, oldName) {
        this.getSelectCheckAll()
      },
      deep: true
    }
  },
  methods: {
    lookUpAndRecord () { // 查看并记录安检数据
      this.$router.push({ path: '/cargo-receiving/security-screening/record-screening-result' })
    },
    openQeury () { // 打开查询弹框
      this.drawer = true
    },
    selectionItem (val) { // 获取选项(安检表格框)
      console.log('selectionItem', val)
      this.dataCheckAll = val
      if (this.dataCheckAll.length === this.dataList.length) {
        this.datacChecked = true
        this.isIndeterminateData = false
      } else if (this.dataCheckAll.length === 0) {
        this.datacChecked = false
        this.isIndeterminateData = false
      } else if (this.dataCheckAll.length > 0 && this.dataCheckAll.length < this.dataList.length) {
        this.isIndeterminateData = true
      }
    },
    selectAllbottomChange (val) { // 获取选项（表格底部）
      val ? this.$refs.multipleTable.toggleAllSelection() : this.$refs.multipleTable.clearSelection()
    },
    sizeChange (val) { // 修改条数
      console.log('sizeChange', val)
    },
    currentChange (val) { // 修改页数
      console.log('currentChange', val)
    },
    optionsChange () { // 修改 header Setting的 Switch （保存我的选项）
      console.log('optionsChange')
    },
    defaultSort () { // 默认获取的初始选线值
      this.dataCols.forEach((item) => {
        this.selectCheckAll.push(item.label)
      })
      this.selectCheckAll.forEach((item) => {
        if (this.leftFixed.indexOf(item) !== -1) {
          this.checkedColsLeft.push(item)
        }
        if (this.notFixed.indexOf(item) !== -1) {
          this.checkedColsNot.push(item)
        }
        if (this.rightFixed.indexOf(item) !== -1) {
          this.checkedColsRight.push(item)
        }
      })
      this.checkAllOption.push(...this.leftFixed, ...this.notFixed, ...this.rightFixed)
    },
    checkAllChange (val) { // 监听header Setting的全选框
      this.selectCheckAll = val ? this.checkAllOption : []
      this.isIndeterminate = false
      this.selectAllFn(this.selectCheckAll)
    },
    checkedColsLeftChange (value) { // 监听header Setting的左侧选中值
      console.log('value', value)
      this.selectAllByItem()
    },
    checkedColsNotChange () { // 监听header Setting的不固定选中值
      this.selectAllByItem()
    },
    checkedColsRightChange () { // 监听header Setting的右侧侧选中值
      this.selectAllByItem()
    },
    onStart () { // 开始拖拽
      this.drag = true
    },
    onEnd () { // 结束拖拽
      this.drag = false
    },
    reset () { // header Setting的 重置按钮
      this.leftFixed = []
      this.notFixed = ['Booking No.', 'Cargo Receiving Job ID', 'Booking Date', 'Est. Arrival at Warehouse Date', 'AWB No.', 'Flight Date', 'Departure', 'Destination', 'Shipper', 'Consignee', 'Total Weight(Kg)', 'Total Volume(CBM)', 'Total Piece(PCS)', 'Screener ID', 'Screening Date', 'Screening Time', 'Screening Method', 'Screening Remark', 'Screening Result', 'Printing Result']
      this.rightFixed = []
      this.selectCheckAll = ['Booking No.', 'Cargo Receiving Job ID', 'Screening Result', 'Printing Result']
      this.checkAll = false
      this.isIndeterminate = true
    },
    confirm () { // header Setting的 确认按钮
      this.dataCols = []
      this.selectCheckAll.forEach(ele => {
        if (this.checkedColsLeft.includes(ele)) {
          this.dataCols.push({
            label: ele,
            location: 'left'
          })
        }
        if (this.checkedColsNot.includes(ele)) {
          this.dataCols.push({
            label: ele,
            location: false
          })
        }
        if (this.checkedColsRight.includes(ele)) {
          this.dataCols.push({
            label: ele,
            location: 'right'
          })
        }
      })
      this.tableShow = false
      this.$nextTick(() => {
        this.tableShow = true
      })
    },
    selectAllFn (selectCheckAll) { // 全选框勾选，把三个选框都选择
      if (!this.checkAll) {
        selectCheckAll = this.disabledColsArray
        this.checkedColsLeft = []
        this.checkedColsNot = []
        this.checkedColsRight = []
      }
      selectCheckAll.forEach((item) => {
        if (this.leftFixed.indexOf(item) !== -1) {
          this.checkedColsLeft.push(item)
        }
        if (this.notFixed.indexOf(item) !== -1) {
          this.checkedColsNot.push(item)
        }
        if (this.rightFixed.indexOf(item) !== -1) {
          this.checkedColsRight.push(item)
        }
      })
      this.checkedColsLeft = unique(this.checkedColsLeft)
      this.checkedColsNot = unique(this.checkedColsNot)
      this.checkedColsRight = unique(this.checkedColsRight)
    },
    selectAllByItem () { // 子选框全部勾选，触发全选框被勾选
      let optionAll = []
      optionAll.push(...this.checkedColsLeft, ...this.checkedColsNot, ...this.checkedColsRight)
      this.selectCheckAll = optionAll
      this.isIndeterminate = optionAll.length > 0 && optionAll.length < this.checkAllOption.length
      this.checkAll = optionAll.length === this.checkAllOption.length
    },
    getSelectCheckAll () { // 监听左固定、不固定、右固定列表的公共方法
      let selectCheckAllCopy = JSON.parse(JSON.stringify(this.selectCheckAll))
      let allCheckArr = []
      allCheckArr.push(...this.leftFixed, ...this.notFixed, ...this.rightFixed)
      this.selectCheckAll = []
      allCheckArr.forEach(i => {
        if (selectCheckAllCopy.includes(i)) {
          this.selectCheckAll.push(i)
        }
      })
      let newcheckedLeft = []
      this.leftFixed.forEach(element => {
        if (this.selectCheckAll.includes(element)) {
          newcheckedLeft.push(element)
        }
      })
      this.checkedColsLeft = newcheckedLeft
      let newcheckedNot = []
      this.notFixed.forEach(element => {
        if (this.selectCheckAll.includes(element)) {
          newcheckedNot.push(element)
        }
      })
      this.checkedColsNot = newcheckedNot
      let newcheckedRight = []
      this.rightFixed.forEach(element => {
        if (this.selectCheckAll.includes(element)) {
          newcheckedRight.push(element)
        }
      })
      this.checkedColsRight = newcheckedRight
    },
    drawerOnConfirm () {
      console.log('drawerOnConfirm')
    },
    draweronReset () {
      console.log('draweronReset')
    },
    draweronClose () {
      this.drawer = false
    },
    showPopover (row, index) {
      console.log('showPopover', row, index)
    },
    hidePopover (row, index) {
      console.log('hidePopover', row, index)
    },
    actionFrame (title, row, index) {
      console.log('actionFrame', title, row, index)
    }
  }
}
</script>

<style lang="scss" scoped>
.total-data-wrap{
  padding: 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .total_data_Item{
    width: 19%;
    background-color: #fff;
    padding: 18px 24px;
    .item-type{
      color: #666666;
      font-size: 14px;
      margin-bottom: 8px;
    }
    .item-value-label-box{
      display: flex;
      align-items: center;
    }
    .item-value{
      font-size: 30px;
      color: #333333;
    }
    .item-label{
      font-size: 14px;
      color: #cccccc;
      margin-left: 8px;
    }
  }
  .colorRejuct{
    color: red !important;
  }
  .colorSuccess{
    color: #52C41A !important;
  }
}
// <!-- 数据列表 -->
.data-list-box{
  padding: 0px 24px 0px 24px;
  .list-box{
    border: 1px solid #e5e5e5;
    background-color: #fff;
    border-radius: 4px;
    .inventory-table{
      width: 100%;
      border-radius: 4px;
      .part-status-flex{
        display: flex;
        align-items: center;
        .dotsSuccess{
          display: inline-block;
          width: 4px;
          height: 4px;
          background-color: #52C41A;
          border-radius: 50%;
          margin-right: 7px;
        }
        .dotsError{
          display: inline-block;
          width: 4px;
          height: 4px;
          background-color: #52C41A;
          border-radius: 50%;
          margin-right: 7px;
        }
        .dotsWarn{
          display: inline-block;
          width: 4px;
          height: 4px;
          background-color: #52C41A;
          border-radius: 50%;
          margin-right: 7px;
        }
      }
      .icon-font {
        cursor: pointer;
      }
    }
    .list-pagination{
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 8px 16px;
      .left-function{
        .el-checkbox:last-of-type{
          margin-right: 24px;
        }
        .el-button{
          padding: 1px 8px;
          font-size: 14px;
          height: 24px;
          line-height: 22px;
          color: #666666;
        }
        .el-button:hover{
          color: #1890ff;
        }
        .el-button:active{
          color: #1890ff;
        }
      }
      .right-function{
        display: flex;
        align-items: center;
        .divider{
          width: 1px;
          height: 24px;
          background-color: #e5e5e5;
          margin: 0 24px;
        }
        .back-top{
          cursor: pointer;
        }
      }
    }
    .bg-image-default{
      display: inline-block;
      width: 22px;
      height: 22px;
      background-image:url('../../../../assets/index/more-actions-default.png');
      background-size: 100%;
      &:hover{
        display: inline-block;
        width: 22px;
        height: 22px;
        background-image:url('../../../../assets/index/more-actions-focuse.png');
        background-size: 100%;
      }
    }
    .bg-image-focuse{
      display: inline-block;
      width: 22px;
      height: 22px;
      background-image:url('../../../../assets/index/more-actions-focuse.png');
      background-size: 100%;
    }
    .bg-image-disabled{
      display: inline-block;
      width: 22px;
      height: 22px;
      // background-image:url('./assets/index/more actions disabled.png');
      background-image: url('../../../../assets/index/more-actions-disabled.png');
      background-size: 100%;
    }
    ::v-deep {
      .el-table__header{
        width: 100% !important;
      }
      .el-table__body{
        width: 100% !important;
      }
      .el-table__empty-block{
        display: none;
      }
      .el-table__fixed-body-wrapper {
        max-height: 100% !important;
        top: 48px !important;
      }
      .is-scrolling-left{
        height: calc(100% - 46px) !important;
      }
      .el-table__header tr,//修改th表头高度
      .el-table__header th {
        padding: 0;
        height: 47px;
        line-height: 47px;
      }
      .el-table-column--selection{
        padding-left: 0;
        border-left: none !important;
      }
      .el-table-column--selection .cell{
        padding-right: 0;
        padding-left: 0;
      }
      .el-table thead {
        color: #666;
        font-size: 14px;
        font-family: 'Roboto Black';
      }
      .el-table{
        color: #333;
      }
      .el-table th>.cell{
        border-left: none;
        padding: 0 12px !important;
      }
      .el-table td>.cell{
        padding: 0 12px !important;
      }
    }
  }
}
</style>
